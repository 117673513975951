<template>
  <div style="position: relative; z-index: 0">
    <v-card flat>
      <v-card-title>
        Search Results (select checkbox)
        <v-spacer />
        <v-spacer></v-spacer>
        {{ employees.length || 0 }} Employee(s) found
      </v-card-title>

      <v-data-table
        dense
        :headers="headers"
        :items="employees"
        class="elevation-1"
        show-select
        v-model="selected"
        single-select
        :loading="loadingSearch"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    employees: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      headers: [
        { text: 'Name', value: 'employeeFullName' },
        { text: 'Market', value: 'market' },
        { text: 'Facility', value: 'facilityName' },
        { text: 'Department', value: 'department' },
        { text: '3-4 ID', value: 'userId' },
        { text: 'Title', value: 'title' }
      ]
    }
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue !== null || undefined) {
        this.$emit('updateEmployeeChoice', newValue)
      }
    }
  },
  computed: {
    ...mapGetters('users', ['loadingSearch'])
  },
  methods: {
  }
}
</script>

<style></style>
